.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
  }
  
  label {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px 0;
    width: 100%;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  select {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    background-color: #f2f2f2;
  }
  
  textarea {
    height: 100px;
    resize: none;
  }
  
  button[type="submit"] {
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
  }
  
  button[type="submit"]:hover {
    background-color: #0062cc;
  }
  
  @media only screen and (max-width: 767px) {
    form {
      max-width: 90%;
    }
  }
