.App {
  text-align: center;
 
}

.italic {
  font-style: italic;
}

.App-logo {
  margin-top: 20px;
  height: 70vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear; */
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CSS code */
.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
  margin: 0 auto;
  max-width: 800px;
}

.service-box {
  border: 2px solid #000;
  border-radius: 5px;
  padding: 8px;
  background-color: lightgreen;
  aspect-ratio: 1/1;
  transform: scale(1.0);
}

.service-box p {
  margin: 0; /* Remove space below and above the paragraph text */
}

/* Media query for mobile devices with a maximum width of 600px */
@media (max-width: 600px) {
  .services-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto; /* Change to auto to adjust height based on content */
  }

  .service-box {
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    aspect-ratio: unset; /* Remove aspect-ratio property to allow height to adjust based on content */
  }
}

