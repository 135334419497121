.avatar-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.text-container {
  /* border: 4px solid black; */
  padding: 20px;
  width: 101%;
  box-sizing: border-box;
  max-width: 800px;
  margin: 0 auto;
}

.text {
  font-family: Arial;
  font-size: 20px;
  line-height: 1.5;
  color: black;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: justify;
}

@media (max-width: 600px) {
  .text-container {
    padding: 10px;
  }
  
  .text {
    text-align: justify;
    word-break: break-word;
    hyphens: auto;
  }
}
